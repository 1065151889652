import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intranet-purchase-summary"
export default class extends Controller {
  connect() {
    document.getElementById('shopping-cart').classList.remove('active');
    document.getElementById('billing-info') && document.getElementById('billing-info').classList.remove('active');
    document.getElementById('purchase-summary').classList.add('active');

    this.alignTotal();
  }

  alignTotal() {
    const totalTarget = document.getElementById('summary-total');
    const partials = Array.from(document.querySelectorAll('.summary-price'));
    const total = partials.reduce((acc, partial) => {
      return acc += parseFloat(partial.innerHTML)
    }, 0).toFixed(2);

    totalTarget.innerHTML = total;
  }

  setPaymentType(event) {
    const selectedPaymentType = event.target.dataset.paymentType;
    const paymentType = document.getElementById('purchase_payment_type');
    const paymentMessage = document.getElementById('payment_message');

    paymentType.value = selectedPaymentType;

    switch (selectedPaymentType) {
      case 'cash':
        paymentMessage.innerHTML = 'Contanti';
        break;
      case 'card':
        paymentMessage.innerHTML = 'Carte di Pagamento';
      default:
        break;
    }
  }
}
