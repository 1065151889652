import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intranet-order-summary"
export default class extends Controller {
  connect() {
    this.alignTotal();
  }

  alignTotal() {
    const totalTarget = document.getElementById('summary-total');
    const partials = Array.from(document.querySelectorAll('.summary-price'));
    const total = partials.reduce((acc, partial) => {
      return acc += parseFloat(partial.innerHTML)
    }, 0).toFixed(2);

    totalTarget.innerHTML = total;
  }
}
