import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="captcha-loader"
export default class extends Controller {
  connect() {
    this.captchaClientKey = this.data.get("captcha-client-key");
    this.captchaType = this.data.get("captcha-type");
    this.action = this.data.get("captcha-action");

    if (this.captchaType === 'turnstile') {
      const triggerElement = document.getElementById("captcha-trigger");

      if (triggerElement) {
        triggerElement.disabled = true;
      }

      const captchaClientKey = this.captchaClientKey;
      const action = ("t__" + this.action).substring(0, 32);

      const captchaLoader = this;

      window.onloadTurnstileCallback = function () {
        captchaLoader.widgetId = turnstile.render("#turnstile-form-container", {
          sitekey: captchaClientKey,
          action: action,
          size: 'normal',
          callback: function (token) {
            const captchaTokenInput = document.querySelector('input[name="captcha_token"]');
            const captchaTokenActionInput = document.querySelector('input[name="captcha_token_action"]');

            if (captchaTokenInput) {
              captchaTokenInput.value = token;
            }

            if (captchaTokenActionInput) {
              captchaTokenActionInput.value = `${action}`;
            }

            if (triggerElement) {
              triggerElement.disabled = false;
            }
          }
        });
      };
    }
  }

  execCaptchaVerify(event) {
    event.preventDefault();

    const captchaClientKey = this.captchaClientKey;
    const action = this.action;

    const form = document.getElementById('captcha-form');

    if (!form.checkValidity()) {
      form.reportValidity();

      return;
    }

    if (this.captchaType === 'recaptcha') {
      grecaptcha.ready(function () {
        grecaptcha.execute(captchaClientKey, { action: `r__${action}` }).then(function (token) {
          const captchaTokenInput = document.querySelector('input[name="captcha_token"]');
          const captchaTokenActionInput = document.querySelector('input[name="captcha_token_action"]');

          if (captchaTokenInput) {
            captchaTokenInput.value = token;
          }

          if (captchaTokenActionInput) {
            captchaTokenActionInput.value = `r__${action}`;
          }

          if (form) { form.submit(); }
        });
      });
    } else if (this.captchaType === 'turnstile') {
      if (this.widgetId) { turnstile.remove(this.widgetId); }

      if (form) { form.submit(); }
    } else {
      if (form) { form.submit(); }
    }
  }

  disconnect() {
    if (this.widgetId) { turnstile.remove(this.widgetId); }

    const turnstileContainer = document.querySelector("#turnstile-form-container");

    if (turnstileContainer) { turnstileContainer.innerHTML = ''; }
  }
}
