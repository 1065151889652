import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intranet-shopping-cart"
export default class extends Controller {
  connect() {
    document.getElementById('shopping-cart').classList.add('active');
    document.getElementById('billing-info') && document.getElementById('billing-info').classList.remove('active');
    document.getElementById('purchase-summary').classList.remove('active');
  }

  alignCart(event) {
    const line = event.target.closest('tr');
    const partial = line.querySelectorAll(':scope .partial').item(0);
    const price = parseFloat(line.querySelectorAll(':scope .price').item(0).innerHTML);
    const quantity = line.querySelectorAll(':scope .quantity').item(0).value;
    const amount = price * quantity;

    partial.innerHTML = amount.toFixed(2);
  }
}
