import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"

// Connects to data-controller="stripe-poller"
export default class extends Controller {
  static targets = ["pollerParams"]

  connect() {
    this.doPoll('', this.pollerParamsTarget.dataset.pollUrl);
  }

  doPoll(intent_id, poll_url) {
    const stripePoller = this;

    $.get(poll_url, { intent_id: intent_id }, function (data) {
      if (data.status === "completed") {
        window.location = data.url;
      } else if (data.status === "error") {
        Swal.fire({
          title: '',
          text: data.message,
          icon: 'error'
        }).then(() => {
          location.reload();
        });
      } else if (data.status === 'requires_action') {
        stripe.handleCardAction(data.payment_intent_client_secret)
          .then(function (result) {
            if (result.error) {
              Swal.fire({
                title: '',
                text: result.error.message,
                icon: 'error'
              }).then(() => {
                location.reload();
              });
            } else {
              stripePoller.doPoll(result.paymentIntent.id, poll_url);
            }
          });
      } else {
        setTimeout(function () { stripePoller.doPoll(intent_id, poll_url) }, 2000);
      }
    });
  }
}