import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user"
export default class extends Controller {
  align_requirements_for_billing_info(event) {
    const town = document.getElementById('billing_info_town');
    const district = document.getElementById('billing_info_district');
    const blankDistrict = document.getElementById('blank_district');
    const address = document.getElementById('billing_info_address');
    const cap = document.getElementById('billing_info_cap');
    const cf = document.getElementById('billing_info_cf');

    if (event.target.value != 'IT') {
      town.removeAttribute('required');
      town.value = '';
      town.readOnly = true;
      district.removeAttribute('required');
      district.value = '';
      district.disabled = true;
      blankDistrict.disabled = false;
      address.removeAttribute('required');
      address.value = '';
      address.readOnly = true;
      cap.removeAttribute('required');
      cap.value = '';
      cap.readOnly = true;
      cf.removeAttribute('required');
      cf.value = '';
      cf.readOnly = true;
    } else {
      town.setAttribute('required', '');
      town.readOnly = false;
      district.setAttribute('required', '');
      district.disabled = false;
      blankDistrict.disabled = true;
      address.setAttribute('required', '');
      address.readOnly = false;
      cap.setAttribute('required', '');
      cap.readOnly = false;
      cf.setAttribute('required', '');
      cf.readOnly = false;
    }
  }
}
