import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intranet-purchase-billing-info"
export default class extends Controller {
  connect() {
    document.getElementById('shopping-cart').classList.remove('active');
    document.getElementById('billing-info').classList.add('active');
    document.getElementById('purchase-summary').classList.remove('active');
  }

  submitForm() {
    document.getElementById("billing-info-form").requestSubmit();
  }
}
