import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"

// Connects to data-controller="cart"
export default class extends Controller {
  connect() {
    const stripeButton = document.getElementById('stripe-pay');
    const stripeSpinner = document.getElementById('stripe-pay-spinner');
    const stripeError = document.getElementById('stripe-error');

    const elements = stripe.elements({ locale: 'it', currency: 'eur', mode: 'setup' });

    // Create an instance of the card Element
    this.card = elements.create('payment');

    // Add an instance of the card Element into the `card` <div>
    this.card.mount('#card');

    this.card.addEventListener('change', function (event) {
      if (event.error) {
        stripeError.classList.remove('visually-hidden');
        stripeError.textContent = event.error.message;

        stripeButton.disabled = false;
        stripeSpinner.classList.add('d-none');
      } else {
        stripeError.textContent = '';
        stripeError.classList.add('visually-hidden');
      }
    });
  }

  submitStripe(event) {
    event.preventDefault();

    const cart = this;
    const stripeButton = document.getElementById('stripe-pay');
    const stripeSpinner = document.getElementById('stripe-pay-spinner');
    const termsAndConditions = document.getElementById('terms-and-conditions');
    const stripeError = document.getElementById('stripe-error');

    stripeButton.disabled = true;
    stripeSpinner.classList.remove('d-none');

    if (!termsAndConditions.checked) {
      Swal.fire({
        title: '',
        text: "Per completare l'acquisto devi prima accettare le condizioni generali di servizio",
        icon: 'error'
      }).then(() => {
        stripeButton.disabled = false;
        stripeSpinner.classList.add('d-none');
      });

      return false;
    }

    stripe.createPaymentMethod('card', this.card).then(function (result) {
      if (result.error) {
        // Inform the user if there was an error
        stripeError.classList.remove('visually-hidden');
        stripeError.textContent = result.error.message;

        stripeButton.disabled = false;
        stripeSpinner.classList.add('d-none');
      } else {
        // Send the token to your server
        cart.stripeTokenHandler(result.paymentMethod);
      }
    });
  }

  stripeTokenHandler(token) {
    const form = document.getElementById('StripeCCForm');
    const hiddenInput = document.createElement('input');

    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'payment_method_id');
    hiddenInput.setAttribute('value', token.id);

    form.appendChild(hiddenInput);

    form.requestSubmit()
  }
}