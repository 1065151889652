import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intranet-point-of-sale"
export default class extends Controller {
  connect() {

  }

  changeTab(event) {
    const buyTab = document.getElementById('buyTab');
    const searchTab = document.getElementById('searchTab');
    const selectedTab = event.target;

    switch (selectedTab) {
      case buyTab:
        searchTab.classList.remove('active');
        buyTab.classList.add('active');
        break;
      case searchTab:
        buyTab.classList.remove('active');
        searchTab.classList.add('active');
        break;
      default:
        break;
    }
  }
}
