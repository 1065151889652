import { Controller } from "@hotwired/stimulus";
import Splide from '@splidejs/splide';

// Connects to data-controller="homepage"
export default class extends Controller {
  connect() {
    document.querySelectorAll('.splide').forEach(carousel => new Splide(carousel, {
      type: 'loop',
      focus: 'center',
      start: 0,
      autoplay: true,
      breakpoints: {
        768: {
          perPage: 1
        }
      }
    }).mount());
  }
}