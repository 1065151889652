import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["currentModal"];

  connect() {
    this.myModal = new bootstrap.Modal(this.currentModalTarget, {});

    let modalHidden = this.currentModalTarget.dataset.modalHidden;

    if (modalHidden == null || modalHidden == 'false') {
      this.myModal.show();
    }
  }

  closeModal() {
    this.myModal.hide();
  }
}
